.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 120px;
  height: 120px;
}

.about_img {
  width: 500px;
}

#Thumbnail {
  padding: 0%;
  width: 500px;
}

.footer {
  display: block;
}

.left_part p {
  text-align: center !important;
}
.countdown-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow boxes to wrap to the next line on smaller screens */
}

.countdown-box {
  text-align: center;
  width: calc(
    25% - 20px
  ); /* Adjust the width based on the number of boxes per row */
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  color: #000000;
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.countdown-box span {
  font-size: 38px;
  display: block;
  margin-bottom: 5px;
}

.countdown-box .value {
  font-weight: bold;
}

.countdown-box .label {
  font-size: 16px;
  color: #555;
  font-weight: 400;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .countdown-box {
    width: calc(25% - 20px); /* Four boxes per row on mobile */
  }
}
.center {
  text-align: center;
}

.centers {
  text-align: center;
  color: #feeb1a;
  font-weight: 700;
  font-size: 42px;
}
.hero_header .buttons1 {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  align-content: center;
  justify-content: center;
}

.details-column {
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.details-column h2 {
  font-size: 1.9rem;
  margin-bottom: 20px;
  font-weight: 700;
  color: #feeb1a;
  text-align: center;
  margin-top: 10px;
}

.details-column table {
  width: 100%;
  border-collapse: collapse;
}

.details-column table td,
.details-column table th {
  padding: 10px;
  border: 2px solid white;
}

.details-column table td:first-child {
  font-weight: bold;
  color: black;
  background: #feeb1a;
  border: black solid 3px;
  width: 40%;
}

.details-column table td:nth-child(2) {
  width: 60%;
  color: black;
  background: white;
  border: black solid 3px;
}

/* Additional styling can be added as needed */

.fn_col22 {
  width: 50%;
  align-content: center;
}

#tokenomics {
  padding: 150px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.titlemall {
  font-size: 32px;
  padding-top: 4px;
  text-align: center;
  font-weight: 200;
  text-transform: uppercase;
}
.timg {
  width: 185px;
  margin: 12px;
  height: auto;
}
.timg2 {
  width: 130px;
}
.timg3 {
  width: 40px;
  margin: 12px;
}
.timg3:hover {
  width: 35px;
  margin-right: 16px;
  transition: 0.1s ease;
}
.social_icons ul {
  margin-left: 10px;
}
.social_icons ul li {
  margin-left: 2px;
}
.timg22 {
  width: 290px;
}
.wimg {
  width: 350px;
  z-index: 222;
}
.timg:hover,
.himg:hover,
.timg2:hover,
wimg:hover,
.timg22:hover,
.conw:hover {
  transform: scale(1.1) rotate(5deg);
  transition: transform 0.3s ease;
}
.himg {
  width: 95px;
  margin: 3px;
}
.himg2 {
  width: 45px;
  margin-left: 1px;
}
.himg2:hover {
  width: 40px;
  margin: 3px;
  transition: transform 0.3s ease;
}

.himg3 {
  width: 45px;
  margin-left: 1px;
}
.himg3:hover {
  width: 40px;
  margin: 3px;
  transition: transform 0.3s ease;
}
@media (max-width: 768px) {
  .logo {
    height: auto;
  }
  .himg3 {
    width: 35px;
    margin-left: 1px;
    align-items: center;
    justify-content: center;
  }
  .metaverse_fn_mobnav .triggers {
    align-items: center;
  }
  .fn_col3 {
    width: 100% !important;
  }
  .fn_row {
    margin: 0px;
  }
  .input-group {
    display: block !important;
  }
  .countdown-box span {
    font-size: 19px;
  }
  .countdown-box .label {
    font-size: 12px;
  }
  .countdown-box {
    padding: 2px;
  }
  .hero_header .buttons {
    display: block !important;
  }
  .about_img {
    width: 350px;
  }
  #Thumbnail {
    width: 350px;
  }
  .fn_col22 {
    width: 100%;
  }
  #about,
  #video,
  #tokenomics,
  #roadmap,
  #team,
  #faq {
    padding-block: 50px;
  }
  #home {
    padding: 50px 0 50px !important;
  }
  .timg {
    width: 150px;
    margin: 12px;
    height: auto;
  }
}

#about,
#video,
#tokenomics,
#roadmap,
#team,
#faq {
  padding: 50px 0 50px;
}
#home {
  padding: 220px 0 90px;
}
.metaverse_fn_main,
.metaverse_fn_main * {
  box-sizing: border-box;
  align-content: center;
}
#about,
#faq {
  background-color: white;
}
.car {
  width: 80%;
  align-content: center;
}

.linkadd {
  text-decoration: none;
  color: black;
}
.dead {
  text-decoration: none;
  color: #eccf48;
  font-size: 24px;
}
.fn_cs_accordion .acc_item {
  padding: 7px 0px 5px;
  border: 2px solid #000;
}

.title22 {
  font-size: 25px;
  font-weight: 600;
  padding-top: 20px;
  color: #000000;
}
.hero_header .title {
  font-size: 35px;
}
.fn_cs_glitch_slider .slider .item,
.fn_cs_roadmap .roadmap_bottom .item {
  border-radius: 20px;
}
.right__border:before {
  border-radius: 0px 20px 0px;
}
.left__border:before {
  border-radius: 20px 0px 0px;
}
.left__border:after {
  border-radius: 0px 0px 0px 20px;
}
.right__border:after {
  border-radius: 0px 0px 20px 0px;
}
.header {
  position: relative;
}
.header {
  transform: translateY(0%);
  background: #0b0c22 !important;
}
#footer {
  background: #0b0c22 !important;
}

.header .nav li {
  margin: 0;
  padding: 0;
  color: white;
  text-decoration: none !important;
  margin-left: 10px;
  position: relative;
}
.header .nav ul a {
  text-decoration: none !important;
}

.btnnew {
  background-image: linear-gradient(to right, #cb9c2d 0%, #ecbb45 100%);
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  font-size: 15px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.btnnews {
  background-image: linear-gradient(to right, #cb9c2d 0%, #ecbb45 100%);
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  font-size: 15px;
  color: white;
  font-weight: 600;
}
body {
  background: linear-gradient(
    267deg,
    rgb(143 117 54) 0%,
    #674522 50%,
    rgb(120 97 38) 100%
  );
}
.header.active,
.metaverse_fn_mobnav .mob_top,
.metaverse_fn_mobnav .mob_bot {
  background: black;
}
.fn_cs_title .divider[data-color="1"] .line:after,
.fn_cs_title .divider[data-color="1"] .line:before,
.fn_cs_title .divider[data-color="1"] div {
  background-color: hsl(36deg 51.72% 17.06%);
}
.centered {
  max-width: 100%; /* Make sure the image doesn't exceed its container */
  height: auto; /* Maintain aspect ratio */
}
.side {
  margin-left: 10px;
}
.solana {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}
.address {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}
.send {
  color: #feeb1a;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}
.SOL {
  text-align: center;
  font-size: 38px;
}
.para {
  color: black;
}

/* Buy Chico */

.data-color {
  color: #000;
}
.title23 {
  font-size: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #999;
  text-align: center;
  margin-bottom: 0%;
}
.input-group {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inline-inputs {
  flex: 1;
}

input[type="text"] {
  padding: 10px;
  margin: 5px;
  width: 50%;
  height: 43px;
  color: #000;
  border: 1px solid #999;
  border-radius: 3px;
  align-items: center;
}
input[type="text"]::placeholder {
  color: #999;
}
input[type="text"]:focus {
  border: 2px solid #000;
}

.buy-button {
  display: block;
  padding: 15px 25px 15px 25px;
  background-color: #68462200;
  color: white;
  border: none;
  font-weight: 700;
  border-radius: 22px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  margin: 0 auto;
}

.buy-button:hover {
  background-color: #999;
  color: #000;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 111111;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  align-content: space-around;
  flex-direction: column;
  font-weight: 700;
}

.close-button {
  background: none;
  border: none;
  border-radius: 50%;
  font-size: 15px;
  padding: 3px;
  float: right;
  cursor: pointer;
}

.popup-content {
  text-align: center;
}

.block-button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  background-color: #feeb1a;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none !important;
  color: black;
}

.block-button:last-child {
  margin-bottom: 0;
}

.title24 {
  font-size: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #000;
  text-align: center;
  margin-bottom: 0%;
}
.fn_col3 {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 40%;
  margin: 0 auto;
  margin: 0 auto 10px;
  background: #ffe167;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
  border: 4px solid #6c4e23;
}
.pl {
  color: black;
  font-size: 32px;
  font-weight: 600;
}
.sol2 {
  color: rgb(53, 52, 52);
  font-size: 20px;
  font-weight: 700;
}
input[type="text"] {
  background-color: white;
}
.ptext {
  color: black;
  font-size: 22px;
  text-align: center;
  font-weight: 700;
}
.labled {
  color: black;
  font-weight: 500;
  margin-left: 5px;
}
.conw {
  cursor: pointer;
  width: 40%;
  text-align: center;
  display: inline-block;
}
.phantom {
  color: black;
  text-decoration: none !important;
}
.block-button a {
  text-decoration: none;
  color: inherit;

  justify-content: space-between;
}
.link {
  color: #715227;
  font-size: 15px;
  padding: 12px;
  font-weight: 500;
}
.donot {
  color: black;
  font-size: 12px;
}
